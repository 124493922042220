// eslint-disable-next-line import/no-extraneous-dependencies
import { withPersist } from 'config/StorePersist';
import { StorePersistKeys } from 'constants/StorePersistKeys';
import { createStore } from 'effector';
import { sortBy } from 'lodash';
import { clearDDCSelectedList } from 'stores/DDCs/DDCsListEvents';
import { clearClientselectedList } from 'stores/DeliveryWindow/DeliveryWindowEvents';
import {
  updateDownloadClientBaseFilter,
  updateDownloadRulesBaseFilter,
} from 'stores/ImportsAndExports/ImportsAndExportsEvents';
import { clearOwnerList } from 'stores/Rules/RulesEvents';
import { trackCountrySelected } from 'tracking/events/generalEvents';
import { getSelectedCountry, getSelectedVendor } from 'utils/persistMetaData';
import {
  resetState,
  setFeatureActivationData,
  setSelectedCountry,
  setSelectedVendor,
  setUserEmail,
} from './MetaDataEvents';
import MetaDataState from './MetaDataState';

export const getInitialState = (): MetaDataState => {
  const defaultSelectedCountry = getSelectedCountry();
  const defaultSelectedVendor = getSelectedVendor();
  return {
    selectedCountry: defaultSelectedCountry,
    selectedVendor: defaultSelectedVendor,
    selectedVendorName: '',
    userEmail: '',
    featureActivation: {
      clickCollectEnabled: false,
      flexDeliveryEnabled: false,
      regularDeliveryEnabled: false,
    },
  };
};

const MetaDataStore = withPersist(
  createStore(getInitialState())
    .on(resetState, () => getInitialState())
    .on(setSelectedCountry, (state, { selectedCountry, vendorList, pathName }) => {
      updateDownloadClientBaseFilter({ ddcs: [] });
      updateDownloadRulesBaseFilter({ ddcs: [] });
      clearDDCSelectedList();
      clearClientselectedList();
      clearOwnerList();
      const [newVendor] = sortBy(
        vendorList.filter((vendor) => vendor.country === selectedCountry),
        'displayName'
      );
      trackCountrySelected(selectedCountry, pathName);
      return {
        ...state,
        selectedCountry,
        selectedVendor: newVendor?.id || '',
        selectedVendorName: newVendor?.displayName || '',
      };
    })
    .on(setSelectedVendor, (state, { vendorId, vendorName }) => {
      updateDownloadClientBaseFilter({ ddcs: [] });
      updateDownloadRulesBaseFilter({ ddcs: [] });
      clearDDCSelectedList();
      clearClientselectedList();
      clearOwnerList();
      return {
        ...state,
        selectedVendor: vendorId,
        selectedVendorName: vendorName,
      };
    })
    .on(setUserEmail, (state, userEmail) => {
      return {
        ...state,
        userEmail,
      };
    })
    .on(setFeatureActivationData, (state, featureActivation) => {
      return {
        ...state,
        featureActivation,
      };
    }),
  {
    whiteList: [
      'selectedCountry',
      'selectedVendor',
      'selectedVendorName',
      'userEmail',
      'featureActivation',
    ],
    key: StorePersistKeys.metaData,
  }
);

export default MetaDataStore;
