import { SearchField as DSSearchField } from '@admin-portal-shared-components/search-field';
import { theme } from '@bees/vision-tokens';
import { ListItem as HexaListItem, SearchField as HexaSearchField } from '@hexa-ui/components';
import {
  Grid,
  Backdrop as MUIBackdrop,
  IconButton as MUIIconButton,
  Switch as MUISwitch,
  TextField as MUITextField,
} from '@material-ui/core';
import { styled } from '@stitches/react';

export const MFE_PREFIX = 'admin-portal-delivery-window-beta-mfe';
export const DSModalZIndex = 3999;

export const Spacer = styled('div', {
  height: '2em',
  variants: {
    half: {
      true: {
        height: '1em',
      },
    },
    third: {
      true: {
        height: '0.66em',
      },
    },
    quarter: {
      true: {
        height: '0.5em',
      },
    },
  },
});

export const List = styled('ul', {
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

export const ListItem = styled(HexaListItem, {
  '& svg': {
    marginRight: '0.5em',
  },
  variants: {
    fullWidth: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
  },
});

export const PageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const DSButtonGroup = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  '& button': {
    '&:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: 0,
    },

    '&:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
});

export const SearchField = styled(DSSearchField, {
  flex: '1!important',
  height: '32px!important',
});

export const SearchFieldRoot = styled(HexaSearchField.Root, {
  '& button[role="combobox"]': {
    marginTop: 0,
  },
});

export const GridItemGrow = styled(Grid, {
  flex: 1,
});

export const GridItemShrink = styled(Grid, {
  flexShrink: 1,
});

export const Backdrop = styled(MUIBackdrop, {
  zIndex: `${DSModalZIndex + 1}!important`,
  color: '#fff',
});

export const Table = styled('table', {
  fontFamily: theme.fonts.paragraph,
  height: '100px',
  width: ' 100%',
  overflow: 'auto',
  borderCollapse: 'collapse',

  variants: {
    stickyHeader: {
      true: {},
    },
  },
});

export const TableHead = styled('thead', {
  '& tr': {
    backgroundColor: `${theme.colors.interfaceSurfacePrimary}!important`,
  },
});

export const TableBody = styled('tbody', {
  minHeight: '180px',
  backgroundColor: theme.colors.neutral0,
});

export const TableRow = styled('tr', {
  borderBottom: `1px solid ${theme.colors.interfaceSurfaceSecondary}`,
  backgroundColor: theme.colors.neutral0,
  '&:hover': {
    backgroundColor: theme.colors.brandAccentNeutralBackgroundOpOpaque,
  },
});

export const TableHeadCell = styled('th', {
  padding: `${theme.space[1]} ${theme.space[4]}`,
  position: 'relative',
  height: '36px',
  textAlign: 'left',
  fontSize: theme.fontSizes[3],

  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
  },
});

export const TableCell = styled('td', {
  padding: `${theme.space[1]} ${theme.space[4]}`,
  fontFamily: theme.fonts.paragraph,
  lineHeight: theme.lineHeights[4],
  fontWeight: theme.fontWeights.normal,
  position: 'relative',
  textAlign: 'left',
  fontSize: theme.fontSizes[3],

  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
  },
});

export const IconButton = styled(MUIIconButton, {
  color: `${theme.colors.interfaceLabelPrimary}!important`,
});

export const Hint = styled('div', {
  fontFamily: theme.fonts.paragraph,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal,
  lineHeight: theme.lineHeights[4],
  color: theme.colors.interfaceLabelSecondary,
  paddingBottom: theme.space[1],
});

export const Label = styled('label', {
  fontFamily: theme.fonts.paragraph,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semibold,
  lineHeight: theme.lineHeights[3],
  marginBottom: theme.space[1],
  color: theme.colors.interfaceLabelPrimary,
  display: 'inline-block',
  variants: {
    hintSpaced: {
      true: {
        marginBottom: `calc(${theme.space[1]} + ${theme.lineHeights[4]})`,
      },
    },
  },
});

export const LabelSuffix = styled('span', {
  fontFamily: theme.fonts.paragraph,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semibold,
  lineHeight: theme.lineHeights[3],
  marginBottom: theme.space[1],
  color: theme.colors.interfaceLabelSecondary,
  display: 'inline-block',
  whiteSpace: 'pre',
});

export const ModalActionsContainer = styled('div', {
  display: 'flex',
  gap: '0.5rem',
  justifyContent: 'flex-end',
});

export const Switch = styled(MUISwitch, {
  [`& .${MFE_PREFIX}-MuiSwitch-colorPrimary.Mui-checked`]: {
    color: `${theme.colors.interfaceLabelPrimary}!important`,
    [`& + .${MFE_PREFIX}-MuiSwitch-track`]: {
      backgroundColor: `${theme.colors.interfaceLabelPrimary}!important`,
    },
  },
});

const TextField = styled(MUITextField, {
  margin: '0!important',
  [`& .${MFE_PREFIX}-MuiFormHelperText-root`]: {
    order: 1,
    margin: 0,
    fontFamily: theme.fonts.paragraph,
    fontSize: theme.fontSizes[1],
    fontWeight: theme.fontWeights.normal,
    lineHeight: theme.lineHeights[4],
    color: theme.colors.interfaceLabelSecondary,
  },
  [`& .${MFE_PREFIX}-MuiInputLabel-outlined`]: {
    order: 0,
    transform: 'none!important',
    position: 'relative',
    lineHeight: theme.lineHeights[3],
    fontWeight: theme.fontWeights.semibold,
    color: `${theme.colors.interfaceLabelPrimary}!important`,
    fontFamily: theme.fonts.paragraph,
    fontSize: theme.fontSizes[2],
    paddingBottom: theme.space[1],
    [`&.${MFE_PREFIX}-MuiFormLabel-root.Mui-disabled`]: {
      color: `${theme.colors.interfaceLabelDisabled}!important`,
    },
  },
  [`& .${MFE_PREFIX}-MuiAutocomplete-inputRoot, & .${MFE_PREFIX}-MuiInputBase-root`]: {
    order: 2,
    borderRadius: theme.radii[2],
    border: `1px solid ${theme.colors.interfaceSurfaceSecondary}`,
    backgroundColor: `${theme.colors.neutral0}!important`,
    fontFamily: theme.fonts.paragraph,
    lineHeight: theme.lineHeights[6],
    fontWeight: theme.fontWeights.normal,
    color: theme.colors.interfaceLabelPrimary,
    height: '40px',
    padding: '5px!important',

    '&.Mui-focused': {
      border: `1px solid ${theme.colors.interfaceLabelPrimary}!important`,
    },
    '& fieldset': {
      display: 'none!important',
    },

    [`& .${MFE_PREFIX}-MuiAutocomplete-input`]: {
      minWidth: '50px',
      paddingRight: '65px!important',
    },
  },
  [`& .${MFE_PREFIX}-MuiChip-root`]: {
    background: theme.colors.semanticInfoBackground,
    color: theme.colors.interfaceLabelPrimary,
    fontSize: `${theme.fontSizes[2]}!important`,
    fontWeight: theme.fontWeights.medium,
    fontFamily: theme.fonts.paragraph,
    '& svg': {
      color: theme.colors.semanticInfoText,
    },
  },
  variants: {
    fluidHeight: {
      true: {
        [`& .${MFE_PREFIX}-MuiAutocomplete-inputRoot, & .${MFE_PREFIX}-MuiInputBase-root`]: {
          height: 'auto',
          minHeight: '40px!important',
        },
      },
    },
    error: {
      true: {
        [`& .${MFE_PREFIX}-MuiAutocomplete-inputRoot, & .${MFE_PREFIX}-MuiInputBase-root`]: {
          borderColor: `${theme.colors.semanticErrorText}!important`,
        },
        '& label': {
          color: `${theme.colors.semanticErrorText}!important`,
        },
      },
    },
  },
});

export const StyledToDS = {
  TextField,
};
