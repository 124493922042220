import { Vendor } from 'admin-portal-shared-services';
import { FeatureActivationData } from 'domains/FeatureActivation';
import { createEvent } from 'effector';

export const resetState = createEvent('resetState');

export const setSelectedCountry = createEvent<{
  selectedCountry: string;
  vendorList: Vendor[];
  pathName: string;
}>('setSelectedCountry');

export const setSelectedVendor = createEvent<{ vendorId: string; vendorName: string }>(
  'setSelectedVendor'
);

export const setUserEmail = createEvent<string>('setUserEmail');

export const setFeatureActivationData = createEvent<FeatureActivationData>(
  'setFeatureActivationData'
);
