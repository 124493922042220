export enum featureToggleItems {
  mock = 'FOR_TESTING_ONLY',
  empty = '',
  clickAndCollect = 'click_and_collect',
  clickAndCollectFullOrderCapacity = 'click_and_collect_full_order_capaciy',
  importsExportsRulesCSVUpload = 'rules_csv_upload',
  importsExportsRulesCSVDownload = 'rules_csv_download',
  enableSunday = 'enable_sunday',
  autoBlock = 'auto_block',
  getFileURIEndpoint = 'getfileuriendpoint',
  noVendorsErrorScreen = 'no_vendors_error_screen',
  applyNewAutomaticParameters = 'apply_new_automatic_parameters',
  applyAudienceSettings = 'apply_audience_settings',
}
