import Client, { ClientDayOfWeekType, ClientDaysOfWeekOrigins } from 'domains/Client';
import { PatchClientData, UpdateClientResponseData } from 'domains/ClientEditData';

export const ClientMock: Client = {
  id: '729338b1-3355-4b55-b0f0-f3547418bf17',
  ddcCode: '555',
  code: 1234,
  document: '15415415488',
  exception: true,
  country: 'BR',
  name: 'Client Name',
  vendorId: 'vendor-id',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};
export const ClientMockException = ClientMock;
export const ClientMockNotException: Client = {
  id: '729338b1-3355-4b55-b0f0-f3547418bf18',
  ddcCode: '556',
  code: 1234,
  document: '15415415488',
  exception: false,
  country: 'US',
  name: 'Client Name 2',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};

export const ClientMockNoFlexData: Client = {
  id: '729338b1-3355-4b55-b0f0-f3547418bf156',
  ddcCode: '556',
  code: 1237,
  document: '15415415480',
  exception: false,
  country: 'US',
  name: 'Client Name 4',
  clickAndCollectActive: true,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  audienceId: 'mock-audience-id',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};
export const ClientMockWithAudienceId = ClientMockNoFlexData;
export const ClientMockWithNoDeliveryData = ClientMockNoFlexData;
export const ClientMockWithNoDeliveryFrequencyData = ClientMockNoFlexData;
export const ClientMockFlexDataOnFirstDayOfWeek: Client = {
  id: '729338b1-3355-4b55-b0f0-f35474112bf17',
  ddcCode: '555',
  code: 1234,
  document: '1541315488',
  exception: true,
  country: 'BR',
  name: 'Client Name 5',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: 100,
      addAmount: 100,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};

export const ClientMockFreeDataOnFirstDayOfWeek: Client = {
  id: '729338b1-3355-4b55-b0f0-f35474112bf17',
  ddcCode: '555',
  code: 1234,
  document: '1541315488',
  exception: true,
  country: 'BR',
  name: 'Client Name 5',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};

export const ClientMockFreeDataWithValuesOnFirstDayOfWeek: Client = {
  id: '729338b1-3355-ta55-b0f0-f35474112bf17',
  ddcCode: '555',
  code: 1254,
  document: '1541317688',
  exception: true,
  country: 'BR',
  name: 'Client Name 8',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};

export const ClientMockAllDaysOfWeek: Client = {
  id: '729338b1-3355-4b55-b0f0-f3547418bf29',
  ddcCode: '556',
  code: 1239,
  document: '15415415488',
  exception: false,
  country: 'US',
  name: 'Client Name 3',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  accountId: 'account-id-mock',
  baselineDate: '2022-01-01T12:00:00Z',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: 500,
      addAmount: 35,
      type: ClientDayOfWeekType.flex,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};

export const ClientMockWithNoDeliveryWithoutValuesData: Client = {
  id: '729338b1-3355-4b55-b0f0-f3547418bf29',
  ddcCode: '556',
  code: 4239,
  document: '15411115488',
  exception: false,
  country: 'US',
  name: 'Client Name 12',
  clickAndCollectActive: true,
  deliveryFrequency: 7,
  baselineDate: '2022-01-01T12:00:00Z',
  accountId: 'account-id-mock',
  daysOfWeek: [
    {
      dayOfWeek: 'monday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'tuesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'wednesday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'thursday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'friday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'saturday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.noDelivery,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
    {
      dayOfWeek: 'sunday',
      minValue: null,
      addAmount: null,
      type: ClientDayOfWeekType.free,
      origin: ClientDaysOfWeekOrigins.Exception,
    },
  ],
};

export const ClientListMock = [
  ClientMock,
  ClientMockNotException,
  ClientMockAllDaysOfWeek,
  ClientMockNoFlexData,
  ClientMockFreeDataOnFirstDayOfWeek,
  ClientMockFreeDataWithValuesOnFirstDayOfWeek,
  ClientMockWithNoDeliveryWithoutValuesData,
];

export const APIReturnClientsListMock = {
  clients: ClientListMock,
  page: 1,
  pageSize: 30,
  totalSize: ClientListMock.length,
};

export const APIReturnClientsEmptyListMock = {
  clients: [],
  page: 1,
  pageSize: 30,
  totalSize: 0,
};

export const UpdateDaysOfWeekResponseDataMock: UpdateClientResponseData = {
  duplicatedClients: [],
  invalidMinValueOrAddAmmountClients: [],
  notFoundClients: [],
  updatedClients: [],
};

export const UpdateDaysOfWeekResponseDataErrorMock: UpdateClientResponseData = {
  duplicatedClients: ['1234'],
  invalidMinValueOrAddAmmountClients: ['1234'],
  notFoundClients: ['1234'],
  updatedClients: ['1234'],
};

export const RemoveClientExceptionFlagResponseDataMock: UpdateClientResponseData = {
  duplicatedClients: [],
  notFoundClients: [],
  updatedClients: [],
};

export const RemoveClientExceptionFlagResponseDataErrorMock: UpdateClientResponseData = {
  duplicatedClients: ['1234'],
  notFoundClients: ['1234'],
  updatedClients: ['1234'],
};

export const PatchClientDataMock: PatchClientData = {
  clickAndCollectActive: true,
  deliveryFrequency: 7,
};
